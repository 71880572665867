<template>
  <div>
    <weightedButtons
      :selected="selected"
      :items="testStatus"
      :totalItems="currentTable.data.length"
      @selectedItem="selectedItem"
      :enableBack="currentTable.enableBack"
      :key="currentTable.id"
      :enableTotal="true"
      :statusBarBig="
        typeof currentTable.statusBarBig != 'undefined'
          ? currentTable.statusBarBig
          : true
      "
    ></weightedButtons>
  </div>
</template>
<script>
import weightedButtons from "@/commonComponents/weightedButtons.vue";

export default {
  props: ["currentTable"],
  components: { weightedButtons },
  data() {
    return { testStatus: {}, selected: [] };
  },
  created() {
    if (this.currentTable.status) {
      this.selected = this.currentTable.status.split(",");
      this.selectedItem(this.selected);
    }
    this.getStatusObject();
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "tableChanged") {
        // this.getStatusObject();
        this.$nextTick(function () {
          this.selectedItem(this.selected);
        });
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  watch: {
    // "currentTable.data": function () {
    //   this.getStatusObject();
    // },
    "currentTable.currentItems": function () {
      this.getStatusObject();
      // this.selectedItem(this.selected)
    },
  },
  methods: {
    getStatusObject() {
      let listedItems = this.currentTable.data;
      // let itemsLength = this.currentTable.dataCount;
      let testStatus = [
        { name:  this.language.testStatusLabel.pending,
          color: "pending",
          index: "0"
        },
        {
          name: this.language.testStatusLabel.running,
          color: "testStatusRunning",
          type: "n_testRunning",
          index: "1",
        },
        {
          name: this.language.testStatusLabel.ok,
          color: "ok",
          type: "n_testsOK",
          index: "2"
        },
        {
          name: this.language.testStatusLabel.resultWarning,
          color: "testStatusWarning",
          type: "n_testsWarning",
          index: "3",
        },
        {
          name: this.language.testStatusLabel.resultError,
          color: "testStatusError",
          type: "n_testsError",
          index: "4",
        },
        {
          name: this.language.testStatusLabel.aborted,
          color: "testStatusAborted",
          type: "n_testsAborted",
          index: "5",
        },
        {
          name: this.language.testStatusLabel.processingWarning,
          color: "testStatusRuntimeWarning",
          type: "n_testsRuntimeWarning",
          index: "7",
        },
        {
          name: this.language.testStatusLabel.processingError,
          color: "testStatusRuntimeError",
          type: "n_testsRuntimeError",
          index: "8",
        },
      ];
      if (this.currentTable.disableRunning) {
         testStatus.splice(0,2);
        // testStatus.splice(1,1);
      }
      // if (this.currentTable.data.length > 0) {
      testStatus.forEach((info) => {
        let i = info.index;
        info["count"] = this.filterStatus(listedItems, i).length;
      });
      // }
      this.testStatus = testStatus;
    },

    filterStatus(source, status) {
      var tStatus = status;
      return source.filter(function (el) {
        return el.n_status == tStatus;
      });
    },
    filterStatusMulti(source, selected) {
      return source.filter(function (el) {
        return selected.includes(el.n_status);
      });
    },
    selectedItem(selected) {
      this.selected = selected;
      let listedItems = this.currentTable.data;
      let filteredItem = this.filterStatusMulti(listedItems, selected);

      if (selected.length == 0) {
        filteredItem = this.currentTable.data;
      }
      this.$set(this.currentTable, "filteredData", filteredItem);
    },
  },
};
</script>
